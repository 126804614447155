import React from 'react';
import ReactDOM from 'react-dom/client';
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './resources/css/bootswatch.min.css';
import './resources/css/index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);