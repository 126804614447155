import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Spinner } from 'react-bootstrap';
import TableFinanceiro from './../../../components/Table/TableFinanceiro';
import { transacaoService } from '../../../services/transacaoService';
import { formatarDataParaInput, getDataMenosDias } from '../../../Util/DataUtil';

const PageFinanceiro = () => {
  const [pageListTransacao, setPageListTransacao] = useState({});
  const [resumoFinanceiro, setResumoFinanceiro] = useState({});
  const [pageParams, setPageParams] = useState({ termoBusca: '', numeroPagina: 1, dataFinal: formatarDataParaInput(new Date()), dataInicial: formatarDataParaInput(getDataMenosDias(30)) });
  const [loading, setLoading] = useState(true);

  const handleDataChange = (dataInicial, dataFinal) => {
    setPageParams({ ...pageParams, dataInicial, dataFinal});
  };

  const handleTransacaoSalva = () => {
    fetchTransacoes();
    fetchResumoFinanceiro();
  };

  useEffect(() => {
    setLoading(true);
    fetchTransacoes();
    fetchResumoFinanceiro();
  }, [pageParams]);

  const fetchTransacoes = () => {
    try {
      transacaoService.get(pageParams).then(response => {
        setPageListTransacao(response);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching transacoes:', error);
    }
  };


  const renderPaginationItems = () => {
    const totalPages = pageListTransacao?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  const fetchResumoFinanceiro = () => {
    try {
      transacaoService.getResumo(pageParams).then(response => {
        setResumoFinanceiro(response);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching transacoes:', error);
    }
  };

  const handlePaginationClick = (page) => {
    if (page > pageListTransacao?.totalPaginas ?? 0) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={loading ? 'vh-100' : ''}>
      {loading ? (
        <div className='d-flex align-items-center justify-content-center p-3'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <TableFinanceiro
            pageListTransacao={pageListTransacao}
            onDataChange={handleDataChange}
            resumoFinanceiro={resumoFinanceiro}
            onTransacaoSaved={handleTransacaoSalva}
          />
          <Row>
            <Col>
              <Pagination className='d-flex justify-content-center pagination-sm'>
                <Pagination.First onClick={() => handlePaginationClick(1)} />
                <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
                {renderPaginationItems()}
                <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
                <Pagination.Last onClick={() => handlePaginationClick(pageListTransacao?.totalPaginas ?? 0)} />
              </Pagination>
            </Col>
          </Row>
          {pageListTransacao?.itens?.length === 0 && (
            <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
          )}
        </>
      )}
    </div>
  );
};

export default PageFinanceiro;
