const gerarLinkWhatsappDuvidas = (whatsapp, idMatricula = null) => {
    if(idMatricula){
        return 'https://api.whatsapp.com/send/?phone=' + '55' + whatsapp + '&text=' + 'Olá, acabo de realizar minha inscrição com a matrícula ' + idMatricula + ' e tenho dúvidas.'
    } else {
        return 'https://api.whatsapp.com/send/?phone=' + '55' + whatsapp + '&text=' + 'Olá, tenho dúvidas sobre o cadastro de inscrição no Crisma Raiz.'
    }
}

export const linkUtil = {
    gerarLinkWhatsappDuvidas
}