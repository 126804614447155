const isValidDate = (dateString) => {
    // Dividir a data em dia, mês e ano
    const [day, month, year] = dateString.split('/');

    // Verificar se é uma data válida
    const dateObject = new Date(`${year}-${month}-${day}`);
    return dateObject instanceof Date && !isNaN(dateObject);
};

const isValidPhoneNumber = (phoneNumber) => {
  const telefoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
  return telefoneRegex.test(phoneNumber);
};

const validarDataPeriodo = (data) => {
    return new Date(data) < new Date("1900-01-01") || new Date(data) > new Date()
}


export {
    isValidDate,
    isValidPhoneNumber,
    validarDataPeriodo
}