import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { UserContext } from '../../../Context/UserContext';
import { AccountService } from '../../../services/accountService';
import { toast } from 'react-toastify';

function FormPerfilAdm() {
  const { user, logout } = useContext(UserContext); // Utilize o contexto do usuário
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [funcaoUsuario, setFuncaoUsuario] = useState(null);

  useEffect(() => {
    if (user && user.userName) {
      setUsername(user.userName);
      const funcao = user?.role == 'adm' ? 'Adminstrador' : 'Catequista';
      setFuncaoUsuario(funcao);
    }
  }, [user]);

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleSenhaAtualChange = (e) => setSenhaAtual(e.target.value);
  const handleNovaSenhaChange = (e) => setNovaSenha(e.target.value);
  const handleConfirmarSenhaChange = (e) => setConfirmarSenha(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (novaSenha.length < 6) {
        alert('A nova senha deve ter no mínimo 6 caracteres.');
        return;
      }

      if (novaSenha !== confirmarSenha) {
        alert('Senhas não conferem!');
        return;
      }

      const userDto = {
        userName: username,
        senha: senhaAtual,
        novaSenha: novaSenha,
        token: null,
        role: null
      };

      try {
        const response = await AccountService.alterarDadosConta(userDto);

        if (!response?.result) return;

        toast.success(response?.message);
        logout();
      } catch (error) {
        console.error('Erro ao alterar dados da conta:', error);
      }
    }

    setValidated(true);
  };

  return (
    <Row className='d-flex justify-content-center'>
      <Col md={8}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="8" controlId="validationCustomUsername" className='mb-2'>
              <Form.Label>Usuário</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome de usuário"
                value={username}
                onChange={handleUsernameChange}
              />
              <Form.Control.Feedback type="invalid">
                Por favor, insira um nome de usuário.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername" className='mb-2'>
              <Form.Label>Função</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={funcaoUsuario}
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustomSenhaAtual" className='mb-2'>
              <Form.Label>Senha Atual</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Senha Atual"
                value={senhaAtual}
                onChange={handleSenhaAtualChange}
              />
              <Form.Control.Feedback type="invalid">
                Por favor, insira sua senha atual.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomNovaSenha" className='mb-2'>
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Nova Senha"
                value={novaSenha}
                onChange={handleNovaSenhaChange}
                minLength={6}
              />
              <Form.Control.Feedback type="invalid">
                A nova senha deve ter no mínimo 6 caracteres.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomConfirmarSenha" className='mb-2'>
              <Form.Label>Confirmar Nova Senha</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Confirmar Nova Senha"
                value={confirmarSenha}
                onChange={handleConfirmarSenhaChange}
                minLength={6}
              />
              <Form.Control.Feedback type="invalid">
                Por favor, confirme a nova senha.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button type="submit">Salvar</Button>
        </Form>
      </Col>
    </Row>
  );
}

export default FormPerfilAdm;
