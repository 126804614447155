import { useState } from 'react';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalVerDocumento({ image, showModal, onClose }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    return (
        <Modal
            show={showModal}
            onHide={onClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                {/* <Modal.Title>Visualizar documento</Modal.Title> */}
                <h6>Visualizar documento</h6>
            </Modal.Header>

            <Modal.Body>
                <Image src={image} fluid className='border-0' />
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fechar</Button>
                </Modal.Footer> */}
        </Modal>
    );
}

export default ModalVerDocumento;