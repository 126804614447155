import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function PageCadastroComunidade({ comunidade, setComunidade }) {
    const [validated, setValidated] = useState(false);
    const [nomeComunidade, setNomeComunidade] = useState("");

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    // Atualiza o estado nomeComunidade quando o valor do campo é alterado
    const handleNomeComunidadeChange = (event) => {
        setNomeComunidade(event.target.value);
        // Atualiza a comunidade no componente pai
        setComunidade({ ...comunidade, nome: event.target.value });
    };

    useEffect(() => {
        if (!!comunidade) {
            setNomeComunidade(comunidade.nome);
        }
    }, [comunidade]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                        required
                        value={nomeComunidade || ""}
                        type="text"
                        placeholder="Nome da comunidade"
                        onChange={handleNomeComunidadeChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, insira um nome para a comunidade.
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
        </Form>
    );
}

export default PageCadastroComunidade;
