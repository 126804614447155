import { toast } from "react-toastify";
import api from "./api/api";
import { createQueryParams } from "../Util/PageParamsUtil";
import { ApiErrorUtil } from "../Util/ApiErrorUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/catequista", dataPost);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const get = async (pageParams) => {
    try {
        const { data } = await api.get(`/catequista${createQueryParams(pageParams)}`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const getId = async (id) => {
    try {
        const { data } = await api.get(`/catequista/id/${id}`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/catequista?id=${id}`);
        toast.success(data);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

export const catequistaService = {
    post,
    get,
    getId,
    deleteData
}
