import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Pagination, Spinner, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faTrash, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { registroPresencaService } from '../../../services/registroPresencaService';
import { candidatoService } from '../../../services/candidatoService';
import { formatarData, formatarDataParaInput, getDataMenosDias } from '../../../Util/DataUtil'; // Importe a função getDataMenosDias

const ModalListaRegistroPresenca = ({ show, onHide, candidatoId }) => {
    const [pageListRegistroPresenca, setPageListRegistroPresenca] = useState({});
    const [candidato, setCandidato] = useState(null);
    const [pageParams, setPageParams] = useState({
        termoBusca: '',
        numeroPagina: 1,
        dataInicial: getDataMenosDias(30), // Defina a data inicial como 30 dias atrás
        dataFinal: getDataMenosDias(0) // Defina a data final como a data atual
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCandidato = async () => {
            try {
                const candidatoData = await candidatoService.getId(candidatoId);
                setCandidato(candidatoData);
            } catch (error) {
                console.error('Erro ao buscar candidato:', error);
            }
        };

        if (show && candidatoId) {
            fetchCandidato();
        }

        // Executa o filtro automaticamente na primeira vez que o componente é montado
        if (show && candidatoId) {
            handleFiltrarClick();
        }
    }, [show, candidatoId]);

    const handleExcluirClick = async (presencaId) => {
        const confirmarExclusao = window.confirm(`Tem certeza que deseja excluir o registro de presença com id #${presencaId}?`);
        if (confirmarExclusao) {
            try {
                await registroPresencaService.deleteData(presencaId);
                const presencasDoCandidato = await registroPresencaService.get(pageParams, candidatoId);
                setPageListRegistroPresenca(presencasDoCandidato);
            } catch (error) {
                console.error('Erro ao excluir presença:', error);
            }
        }
    };

    const handleFiltrarClick = async () => {
        setLoading(true);
        try {
            const presencasDoCandidato = await registroPresencaService.get(pageParams, candidatoId);
            setPageListRegistroPresenca(presencasDoCandidato);
            setLoading(false);
        } catch (error) {
            console.error('Erro ao buscar presenças:', error);
            setLoading(false);
        }
    };

    const handlePaginationClick = (page) => {
        setPageParams({ ...pageParams, numeroPagina: page });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderPaginationItems = () => {
        const totalPages = pageListRegistroPresenca?.totalPaginas ?? 0;
        const currentPage = pageParams.numeroPagina;
        const displayPages = 6;
        const items = [];

        let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
        let endPage = Math.min(totalPages, startPage + displayPages - 1);

        if (totalPages <= displayPages) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (endPage - startPage < displayPages - 1) {
                startPage = endPage - displayPages + 1;
            }
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePaginationClick(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Modal size='xl' show={show} onHide={onHide} centered scrollable>
            <Modal.Header closeButton>
                <h6><FontAwesomeIcon icon={faUser} /> Lista de Presença - {candidato?.nome}</h6>
            </Modal.Header>

            <Modal.Body>
                <>
                    <Form className='mb-2'>
                        <Row>
                            <Col className='d-flex align-items-center'>
                                <Form.Group controlId="formDateInicial" className='me-2'>
                                    <Form.Label>Data Inicial:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={formatarDataParaInput(pageParams.dataInicial)} // Exibe a data inicial formatada
                                        onChange={(e) => setPageParams({ ...pageParams, dataInicial: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formDateFinal">
                                    <Form.Label>Data Final:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={formatarDataParaInput(pageParams.dataFinal)} // Exibe a data final formatada
                                        onChange={(e) => setPageParams({ ...pageParams, dataFinal: e.target.value })}
                                    />
                                </Form.Group>
                                <Row className='d-flex align-items-center mt-4 ms-2'>
                                    <Col className='d-flex justify-content-start align-items-center'>
                                        <Button onClick={handleFiltrarClick} className='ms-auto'>Filtrar</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    {loading ? (
                        <div className='d-flex align-items-center justify-content-center p-3'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {pageListRegistroPresenca?.itens && pageListRegistroPresenca.itens.length > 0 ? (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Data</th>
                                            <th>Presente</th>
                                            <th>Observação</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageListRegistroPresenca?.itens?.map(presenca => (
                                            <tr key={presenca.id}>
                                                <td>#{presenca.id}</td>
                                                <td>{formatarData(presenca.data, true)}</td>
                                                <td>
                                                    {<FontAwesomeIcon icon={faDotCircle} className={presenca?.presente ? `text-success` : 'text-danger'} />} {presenca.presente ? 'Sim' : 'Não'}
                                                </td>
                                                <td>{presenca.observacao}</td>
                                                <td className='text-center'>
                                                    <Button variant="outline-danger" onClick={() => handleExcluirClick(presenca.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <h5 className='text-muted text-center mt-3'>Não há registros de presença para este candidato.</h5>
                            )}
                            {pageListRegistroPresenca?.itens?.length > 0 && (
                                <Pagination className='d-flex justify-content-center pagination-sm'>
                                    <Pagination.First onClick={() => handlePaginationClick(1)} />
                                    <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
                                    {renderPaginationItems()}
                                    <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
                                    <Pagination.Last onClick={() => handlePaginationClick(pageListRegistroPresenca?.totalPaginas ?? 0)} />
                                </Pagination>
                            )}
                        </>
                    )}
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalListaRegistroPresenca;
