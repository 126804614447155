const input = document.querySelector(".pesquisa");
const buttons = document.querySelectorAll("button");
const footer = document.querySelector("footer");
const nav = document.querySelector("nav");

const esconderElementos = () => {
    nav.classList.add("d-none");
    footer.classList.add("d-none");
    buttons.forEach(b => {
        b.classList.add("d-none");
    });
    input.classList.add("d-none");
};

const exibirElementos = () => {
    nav.classList.remove("d-none");
    footer.classList.remove("d-none");
    input.classList.remove("d-none");
    buttons.forEach(b => {
        b.classList.remove("d-none");
    });
}

const candidatos = () => {
    esconderElementos();
    window.print();
    exibirElementos();
}

export const relatorio = {
    candidatos
}