import { ApiErrorUtil } from "../Util/ApiErrorUtil";
import api from "./api/api";

const login = async (loginDto) => {
    try {
        const { data } = await api.post("/Account/Login", loginDto);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const alterarDadosConta = async (dataPost) => {
    try {
        const { data } = await api.put("/Account", dataPost);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

export const AccountService = {
    login,
    alterarDadosConta
}