import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { linkUtil } from '../../Util/LinkUtil';

const getCurrentYear = () => {
    const year = new Date().getFullYear();
    return year;
}

function FooterPrincipal() {
    return (
        <footer className="py-3 bg-dark">
            <div className='container'>
                {/* <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                    <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
                    <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Features</a></li>
                    <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Pricing</a></li>
                    <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">FAQs</a></li>
                    <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li>
                    </ul> */}
                <span className="d-block text-center text-white">
                    <p>Igreja de nome tal localizada em tal lugar</p>

                    <span className='d-flex justify-content-center flex-column'>
                        <span><FontAwesomeIcon className='text-success' icon={faWhatsapp} /> <a target='_blank' className='text-light text-decoration-none' href={linkUtil.gerarLinkWhatsappDuvidas('5521988350766')}> (21) 98835-0766 (Verônica)</a></span>
                        <span className='mb-1'></span>
                        <span><FontAwesomeIcon className='text-success' icon={faWhatsapp} /><a target='_blank' className='text-light text-decoration-none' href={linkUtil.gerarLinkWhatsappDuvidas('5521988350767')}> (21) 98835-0767 (Cidinho)</a></span>
                    </span>
                    <small className='d-block mt-3'>
                        ©{getCurrentYear()}
                        <a className='ms-1 text-light' href='https://danielsanchesdev.com.br' target='_blank'>Daniel Sanches</a>
                    </small>
                </span>

            </div>
        </footer>
    );
}

export default FooterPrincipal;
