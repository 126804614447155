const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    return new Date().toJSON();
}

const formatarData = (data, mostrarHora = false) => {
    if (data == null) return null;

    var newDate = new Date(data);
    var dd = String(newDate.getDate()).padStart(2, '0');
    var mm = String(newDate.getMonth() + 1).padStart(2, '0');
    var yyyy = newDate.getFullYear();
    var hora = String(newDate.getHours()).padStart(2, '0');
    var minutos = String(newDate.getMinutes()).padStart(2, '0');
    var segundos = String(newDate.getSeconds()).padStart(2, '0');

    if (mostrarHora) {
        data = dd + '/' + mm + '/' + yyyy + ' ' + hora + ':' + minutos + ':' + segundos;
    } else {
        data = dd + '/' + mm + '/' + yyyy;
    }

    return data;
}

const formatarDataParaInput = (data, incluirHora = false) => {
    var newDate = new Date(data);
    var dd = String(newDate.getDate()).padStart(2, '0');
    var mm = String(newDate.getMonth() + 1).padStart(2, '0');
    var yyyy = newDate.getFullYear();
    var hora = incluirHora ? `T${String(newDate.getHours()).padStart(2, '0')}:${String(newDate.getMinutes()).padStart(2, '0')}:${String(newDate.getSeconds()).padStart(2, '0')}` : '';

    data = yyyy + '-' + mm + '-' + dd + hora;

    return data;
}

const getDataMenosDias = (days) => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);

    return pastDate.toJSON();
};

const dataEhMaiorQueHoje = (data) => {
    const today = new Date();
    const dataComparacao = new Date(data);

    // Removing the time part from today's date for accurate comparison
    today.setHours(0, 0, 0, 0);

    // If dataComparacao is greater than today, return true, else return false
    return dataComparacao > today;
}

const obterAnos = (periodo = 100) => {
    const anoAtual = new Date().getFullYear();
    const anos = [];
    for (let i = 0; i < periodo; i++) {
        anos.push(anoAtual - i);
    }
    return anos;
}

const obterDiasDoMes = (mes, ano = new Date().getFullYear()) => {
    if (mes < 1 || mes > 12) {
        throw new Error("Mês inválido. Deve ser um valor entre 1 e 12.");
    }

    const ultimoDia = new Date(ano, mes, 0).getDate();
    const dias = [];

    for (let i = 1; i <= ultimoDia; i++) {
        dias.push(i);
    }

    return dias;
}

export {
    getCurrentDate,
    formatarData,
    formatarDataParaInput,
    getDataMenosDias,
    dataEhMaiorQueHoje,
    obterAnos,
    obterDiasDoMes
}