import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavPrincipal from './components/NavPrincipal/NavPrincipal';
import FooterPrincipal from './components/FooterPrincipal/FooterPrincipal';
import PageCadastro from './pages/Cadastro/PageCadastro';
import { ToastContainer } from 'react-toastify';
import Admin from './pages/Admin/Admin';
import Login from './pages/Login/Login';
import { UserProvider } from './Context/UserContext';
import PageComunidades from './pages/Admin/Comunidade/PageComunidades';
import PageFinanceiro from './pages/Admin/Financeiro/PageFinanceiro';
import PageCatequistas from './pages/Admin/Catequista/PageCatequistas';
import PageCadastroCatequista from './pages/Admin/Catequista/PageCadastroCatequista';
import PageInscricoes from './pages/Admin/Inscricao/PageInscricoes';
import PageDownloadArquivo from './pages/Admin/DownloadArquivos/PageDownloadArquivo';
import FormPerfilAdm from './pages/Admin/Perfil/FormPerfilAdm';

function App() {
  return (
    <Router>
      <UserProvider>
        <NavPrincipal />

        <Container className='my-2'>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored" />
          <Routes>
            <Route path="/" element={<Admin />} />
            <Route path="/login" element={<Login /> } />
            <Route path="/cadastro" element={<PageCadastro />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/comunidades" element={<PageComunidades />} />
            <Route path="/admin/inscricoes" element={<PageInscricoes />} />
            <Route path="/admin/financeiro" element={<PageFinanceiro />} />
            <Route path="/admin/catequistas" element={<PageCatequistas />} />
            <Route path="/admin/catequistas/novo" element={<PageCadastroCatequista />} />
            <Route path="/admin/catequistas/editar/:id" element={<PageCadastroCatequista />} />
            <Route path="/admin/downloads" element={<PageDownloadArquivo />} />
            <Route path="/admin/perfil" element={<FormPerfilAdm />} />
            {/* <Route path="/admin/configuracao" element={<PageConfiguracao />} /> */}
          </Routes>
        </Container>

        <FooterPrincipal/>
      </UserProvider>
    </Router>
  );
}

export default App;