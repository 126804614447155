import { faArrowLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalDeletarCandidato = ({ show, onHide, candidato, onConfirmDelete }) => {
    const handleConfirmDelete = () => {
        onConfirmDelete(candidato);
    };

    return (
        <Modal show={show} onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Deletar candidato</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Deseja realmente deletar o candidato "<strong>{candidato?.nome ?? ''}"</strong> com matrícula n°<strong>{candidato?.id ?? 0}</strong>?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Não</Button>
                <Button variant="danger" onClick={handleConfirmDelete}><FontAwesomeIcon icon={faTrashCan} /> Sim</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeletarCandidato;
