import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Pagination, Spinner, Button, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { catequistaService } from '../../../services/catequistaService';
import { formatarData } from '../../../Util/DataUtil';

function PageCatequistas() {
  const [pageListCatequista, setPageListCatequista] = useState({});
  const [pageParams, setPageParams] = useState({ termoBusca: '', numeroPagina: 1 });
  const [catequistaToDelete, setCatequistaToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteCatequista = (catequista) => {
    setCatequistaToDelete(catequista);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    catequistaService.deleteData(catequistaToDelete?.id ?? 0).then(() => {
      fetchCatequistas();
    });
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const fetchCatequistas = () => {
    try {
      catequistaService.get(pageParams).then(response => {
        setPageListCatequista(response);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching catequistas:', error);
    }
  };

  useEffect(() => {
    fetchCatequistas();
  }, [pageParams]);

  const handleSearchChange = (event) => {
    setPageParams({ ...pageParams, termoBusca: event.target.value });
  };

  const handlePaginationClick = (page) => {
    if (page > pageListCatequista?.totalPaginas ?? 0) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderPaginationItems = () => {
    const totalPages = pageListCatequista?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className={loading ? 'vh-100' : ''}>
      <Row>
        <Col sm={12} md={12}>
          {/* Main content */}
          <h2>Catequistas</h2>
          <Form.Control
            size="md"
            type="text"
            className='mb-2'
            placeholder="Buscar"
            value={pageParams.termoBusca}
            onChange={handleSearchChange}
          />
          <small className='d-block text-muted text-end mb-2'>Exibindo <strong>{pageListCatequista?.itens?.length ?? 0}</strong> de um total de <strong>{pageListCatequista?.qtdResultados ?? 0}</strong> resultados</small>
        </Col>
      </Row>

      {loading ? (
        <div className='d-flex align-items-center justify-content-center p-3'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Row>
            <Col className='d-flex justify-content-end'>
              <Button onClick={() => window.location.href = "/admin/catequistas/novo"}><FontAwesomeIcon icon={faPlus} /> Novo</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Dt. Início Ativ.</th>
                    <th>Dt. Fim Ativ.</th>
                    <th>Comunidade</th>
                    <th>Data Cadastro</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pageListCatequista?.itens?.map((catequista) => (
                    <tr key={catequista.id}>
                      <td onClick={() => window.location.href = "/admin/catequistas/editar/" + catequista?.id}>{catequista.nome}</td>
                      <td onClick={() => window.location.href = "/admin/catequistas/editar/" + catequista?.id}>{formatarData(catequista.dataInicioAtividade)}</td>
                      <td onClick={() => window.location.href = "/admin/catequistas/editar/" + catequista?.id}>{formatarData(catequista.dataTerminoAtividade)}</td>
                      <td onClick={() => window.location.href = "/admin/catequistas/editar/" + catequista?.id}>{catequista?.comunidade?.nome}</td>
                      <td onClick={() => window.location.href = "/admin/catequistas/editar/" + catequista?.id}>{formatarData(catequista?.createdAt, true)}</td>
                      <td>
                        <Button variant='outline-danger' onClick={() => handleDeleteCatequista(catequista)}><FontAwesomeIcon icon={faTrash} /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}

      {!loading && (
        <Row>
          <Col>
            <Pagination className='d-flex justify-content-center pagination-sm'>
              <Pagination.First onClick={() => handlePaginationClick(1)} />
              <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
              {renderPaginationItems()}
              <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
              <Pagination.Last onClick={() => handlePaginationClick(pageListCatequista?.totalPaginas ?? 0)} />
            </Pagination>
          </Col>
        </Row>
      )}

      {!loading && pageListCatequista?.itens?.length === 0 && (
        <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
      )}

      <Modal show={showDeleteModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir este catequista?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PageCatequistas;
