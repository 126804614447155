const obterCidades = async (uf) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/distritos`);
      if (!response.ok) {
        throw new Error('Erro ao obter cidades');
      }
      const data = await response.json();
      
      data.sort((cidade1, cidade2) => cidade1.nome.localeCompare(cidade2.nome));
  
      return data;
    } catch (error) {
      return [];
    }
  }

export const ibgeService = {
    obterCidades
}