import { formatarDataParaInput, getDataMenosDias } from "./DataUtil";

const createPageParams = (termoBusca, numeroPagina, tamanhoPagina = 20) => {
    return {
        termoBusca,
        numeroPagina,
        tamanhoPagina
    }
}

const createQueryParams = (pageParams) => {
    let queryParam = "?";
    if (pageParams?.termoBusca) {
        queryParam += `termoBusca=${pageParams?.termoBusca ?? ''}`;
    }

    if (queryParam != "?") {
        queryParam += "&";
    }

    queryParam += `numeroPagina=${pageParams?.numeroPagina ?? 1}&tamanhoPagina=${pageParams?.tamanhoPagina ?? 20}&dataInicial=${pageParams?.dataInicial ?? formatarDataParaInput(getDataMenosDias(365))}&dataFinal=${pageParams?.dataFinal ?? new Date().toISOString()}`;

    if (queryParam == '?') queryParam = "";
    return queryParam;
}

export {
    createPageParams,
    createQueryParams
}