import React, { useState } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleArrowUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatarData, formatarDataParaInput, getCurrentDate, getDataMenosDias } from '../../Util/DataUtil';
import CardsResumoFinanceiro from '../Financeiro/CardsResumoFinanceiro';
import ModalEditarTransacao from './../Modal/Financeiro/ModalEditarTransacao';
import ModalDeletarTransacao from './../Modal/Financeiro/ModalDeletarTransacao';
import { transacaoService } from '../../services/transacaoService'; // Importe o serviço de transação

function TableFinanceiro({ pageListTransacao, resumoFinanceiro, onDataChange }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTransacaoToDelete, setSelectedTransacaoToDelete] = useState(null);

  const [dataInicial, setDataInicial] = useState(() => {
    const dataAnterior = getDataMenosDias(30);
    return formatarDataParaInput(dataAnterior);
  });

  const [dataFinal, setDataFinal] = useState(() => {
    return formatarDataParaInput(getCurrentDate());
  });

  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [selectedTransacao, setSelectedTransacao] = useState({
    data: formatarDataParaInput(new Date(), true),
    descricao: '',
    valor: '',
    tipo: -1
  });

  const handleFiltrarClick = () => {
    onDataChange(dataInicial, dataFinal);
  };

  const handleDataInicialChange = (e) => {
    const value = e.target.value;
    setDataInicial(value);
  };

  const handleDataFinalChange = (e) => {
    const value = e.target.value;
    setDataFinal(value);
  };

  const handleNovaTransacaoClick = () => {
    setSelectedTransacao({
      data: formatarDataParaInput(new Date(), true),
      descricao: '',
      valor: '',
      tipo: -1
    });
    setShowModal(true); // Abrir o modal ao clicar em "Nova"
  };

  const handleEditClick = (transacao) => {
    setSelectedTransacao(transacao);
    setShowModal(true);
  };

  // Função para lidar com o clique no botão de exclusão
  const handleDeleteClick = (transacao) => {
    setSelectedTransacaoToDelete(transacao);
    setShowDeleteModal(true);
  };

  // Função para lidar com a exclusão confirmada
  const handleConfirmDelete = async () => {
    try {
      await transacaoService.deleteData(selectedTransacaoToDelete.id);
      // Atualizar os dados após a exclusão
      onDataChange(dataInicial, dataFinal);
      setShowDeleteModal(false);
    } catch (error) {
      // Tratar erros
      console.error("Erro ao excluir transação:", error);
    }
  };

  return (
    <Row className='d-flex justify-content-center'>
      <Col md={12}>
        <Row className='d-flex justify-content-md-end'>
          <Col className='col-6 col-md-3'>
            <label htmlFor="" className='w-100'>
              Data inicial
              <input className='form-control' type='date' value={dataInicial} onChange={handleDataInicialChange} />
            </label>
          </Col>
          <Col className='col-6 col-md-3'>
            <label htmlFor="" className='w-100'>
              Data final
              <input className='form-control' type='date' value={dataFinal} onChange={handleDataFinalChange} />
            </label>
          </Col>
          <Col md={2} lg={1}>
            <Button className='my-2 my-md-4' onClick={handleFiltrarClick}>Filtrar</Button>
          </Col>
        </Row>
      </Col>

      <Row className='d-flex justify-content-center'>
        <Col md={12}>
          <small className='d-block text-muted text-end'>Exibindo <strong>{pageListTransacao?.itens?.length ?? 0}</strong> de um total de <strong>{pageListTransacao?.qtdResultados ?? 0}</strong> resultados</small>
        </Col>
      </Row>

      <Col md={12} className='mb-2'>
        <CardsResumoFinanceiro resumoFinanceiro={resumoFinanceiro} />
      </Col>

      <Col md={12} className='d-flex justify-content-end'>
        <Button className='my-2' onClick={handleNovaTransacaoClick}><FontAwesomeIcon icon={faPlus} /> Nova</Button>
      </Col>

      <Col>
        <Table striped hover>
          <thead>
            <tr>
              <th>Valor</th>
              <th>Descrição</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pageListTransacao?.itens?.map((transacao) => (
              <tr key={transacao.id}>
                <td className={transacao.valor < 0 ? 'text-danger' : 'text-success'} onClick={() => handleEditClick(transacao)}>
                  <FontAwesomeIcon icon={transacao.valor < 0 ? faCircleArrowUp : faCircleArrowDown} /> R$ {transacao.valor.toFixed(2).replace('.', ',')}
                </td>
                <td onClick={() => handleEditClick(transacao)}>{transacao?.descricao}</td>
                <td onClick={() => handleEditClick(transacao)}>{formatarData(transacao.data, true)}</td>
                <td>
                  <Button variant='outline-danger' onClick={() => handleDeleteClick(transacao)}><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>

      {/* Modal de edição */}
      <ModalEditarTransacao
        show={showModal}
        transacao={selectedTransacao}
        onHide={() => {setShowModal(false); onDataChange(dataInicial, dataFinal)}}
      />

      {/* Modal de exclusão */}
      <ModalDeletarTransacao
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        transacao={selectedTransacaoToDelete}
        onConfirmDelete={handleConfirmDelete}
      />
    </Row>
  );
}

export default TableFinanceiro;
